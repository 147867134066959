import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/sezer/Documents/Projects/talepnet-landing/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Hint } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "console"
    }}>{`Console`}</h2>
    <p>{`TalepNET Satın Alma Süreç Yönetiminde operasyon ekranları "Konsol" üzerinde yer alır. Konsol, yazılımın ana sayfasıdır. Programda uygulamaya ait tanım işlemleri ekranın solundaki menüler üzerinden gerçekleşirken "Konsol" ekranında ise asıl ana operasyona ait fonksiyonlar yer alır. Konsol, widget menülerinden oluşmaktadır:`}</p>
    <ul>
      <li parentName="ul">{`Taleplerim`}</li>
      <li parentName="ul">{`Depo`}</li>
      <li parentName="ul">{`Talep Onayları`}</li>
      <li parentName="ul">{`Satın Alma Onayları`}</li>
      <li parentName="ul">{`Satın Alma`}</li>
      <li parentName="ul">{`Muhasebe`}</li>
    </ul>
    <p>{`Her widget menü tüm kullanıcılarda görünür değildir. Bazı menülerin görünür olması için kullanıcının atandığı pozisyonda belirli rollerin olması gereklidir.`}</p>
    <p>{`Tüm kullanıcılar depo veya satın alma için talep oluşturabilir ve önceki taleplerini takip edebilir. Bu nedenle "Taleplerim" menüsü tüm kullanıcılarda standart olarak gelir.`}</p>
    <h2 {...{
      "id": "taleplerim"
    }}>{`Taleplerim`}</h2>
    <p>{`Programdaki her kullanıcının "Konsol" sayfasında standart olarak gelir. Yeni talep oluşturma, devam eden talepleri izleme ve tamamlanan talepleri görüntüleme işlemleri yapılır. Yeni bir talep oluşturmak için önce "Devam Eden Talepler" tıklanarak gelen sayfada (+) ile yeni talep formu yaratılır.`}</p>
    <h3 {...{
      "id": "devam-eden-talepler"
    }}>{`Devam Eden Talepler`}</h3>
    <p>{`Kullanıcı tarafından daha önce oluşturulan ve henüz işlemleri devam eden talepler listelenir. Her kullanıcı yalnız kendisinin oluşturduğu talepleri görebilir, diğer kullanıcıların taleplerini göremez. Kullanıcı taleplerinin mevcut durumunu takip edebilir. Aynı zamanda her bir talebe ait gerçekleşen iş akışıyla ilgili detaylara talep satırındaki "Proses" menüsüne tıklayarak ulaşabilir. Proses barında talebin ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <p><strong parentName="p">{`Yeni Talep`}</strong></p>
    <ul>
      <li parentName="ul">{`Ürün: Malzeme/hizmet seçilir.`}</li>
      <li parentName="ul">{`Miktar: Miktar yazılır.`}</li>
      <li parentName="ul">{`Birim: Birim seçilir.`}</li>
      <li parentName="ul">{`Açıklama: Açıklama yazılır.`}</li>
    </ul>
    <p>{`Talep kaydedildikten sonra geçerli prosese uygun olarak "Talep Onayı", "Depo" veya "Satın Alma" adımındaki ilişkili pozisyonlara otomatik olarak yönlenir.`}</p>
    <h3 {...{
      "id": "tamamlanan-talepler"
    }}>{`Tamamlanan Talepler`}</h3>
    <p>{`Kullanıcı tarafından daha önce oluşturulmuş ve süreci olumlu veya olumsuz olarak tamamlanmış tüm talepler listelenir. Kullanıcı liste üzerinde geçmiş taleplerinin durumunu görüntüleyebilir ve proses barında ilgili talebine ait proses adımlarını ve detaylarını inceleyebilir.`}</p>
    <h2 {...{
      "id": "depo"
    }}>{`Depo`}</h2>
    <p>{`"Depo widget'ı yalnız "Depo" rolüne sahip bir pozisyona atanmış kullanıcı tarafından görüntülenebilir. Satın alma sürecinde taleplerin işletilmesinde depo ile ilgili operasyonların yürütülmesini sağlar.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
TalepNET sistemindeki "Depo" rolü klasik depo kavramından ziyade ürün/hizmetin karşılanmasından sorumlu olan pozisyon anlamındadır. Kullanıcı fiziksel malzeme talebi 
(kalem veya a4 kağıt gibi) yapabileceği gibi hizmet (eğitim, danışmanlık vb.) talebi de yapabilir. Belirlenen "Depo" pozisyonunun gelen hizmet taleplerindeki 
"Teslim Et" aksiyonu hizmetin sağlandığı, yerine getirildiği anlamına gelir.
    </Hint>
    <p>{`Talep ve satın alma prosesi her sektöre ve firmaya göre değişkenlik gösterebilir. Yapılan bir talep doğrudan satın almaya gidebileceği gibi öncesinde bir veya birden fazla yöneticinin onayı da istenebilir. Eğer firmanızda bir ana depo mantığı varsa ve yapılan kullanıcı talepleri eğer ana deponuzda var olduğunda stoktan karşılanıyorsa o zaman TalepNET prosesinde "Satın Alma" öncesinde "Depo" adımı ekleyerek çözüm sağlayacaksınız demektir.`}</p>
    <h3 {...{
      "id": "yeni-talepler"
    }}>{`Yeni Talepler`}</h3>
    <p>{`Depoya yeni gelen talepler listelenir. Temel prensip olarak elde olan stoklar teslim edilerek olmayanlar satın almaya yönlendirilir. "Depo" rolüne sahip kullanıcı, gelen talepleri listeler ve her biri için uygun işlemleri yapar. Eğer prosesi oluşturulduysa "Depo" tarafından "Teslim Et", "Reddet", "Satın Alma Gerekli" ve "Böl" işlemleri yapılabilir. Bu aksiyonların varlığı ve seçildiklerinde talebin yönleneceği yeni proses adımını "Proses" üzerinde firma başlangıçta kendisi tasarlar.`}</p>
    <p>{`Talep edilen ürünlere ait ana depo stok durumu talep satırlarında "Envanter" menüsü seçilerek görüntülenebilir.`}</p>
    <p>{`Talebin iş akışıyla ilgili detaylara talep satırındaki "Proses" menüsüne tıklayarak ulaşabilir. Proses barında talebin ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <p>{`Aksiyonlar:`}</p>
    <ul>
      <li parentName="ul">{`Teslim Et: Talep edilen ürün (malzeme/hizmet) teslim edilir. Eğer stok takibi yapılan bir ürün ise sistem öncelikle ana depoda ürün için yeterli stok olup olmadığını kontrol eder ve yoksa işlem gerçekleştirilmeyerek kullanıcıya uyarı verir. Eğer yeterli stok varsa işlem gerçekleştirilir. Teslim işlemi stok hareketi yaratır ve ana depoda stok azalır. Ürün teslimi ana depodan alt depoya bir stok transferi değil, doğrudan tüketim gibidir.`}</li>
      <li parentName="ul">{`Teslim işlemi sonunda talebin süreci sonlanır. Talep artık "Yeni Talepler" listesinden "Tamamlanan Talepler" listesine aktarılır.`}</li>
      <li parentName="ul">{`Reddet: Talep reddedilir. Ret işlemi sonunda talebin süreci sonlanır. Talep artık "Yeni Talepler" listesinden "Tamamlanan Talepler" listesine aktarılır.`}</li>
      <li parentName="ul">{`Satın Alma Gerekli: Talep satın almaya yönlendirilmek istenirse seçilir. Proses tasarımına bağlı olarak talep satın almaya veya yönetici onayına gider. İşlem sonunda talep artık "Yeni Talepler" listesinden "Devam Eden Talepler" listesine aktarılır. Depo kullanıcısı ihtiyaç duyduğunda bu liste üzerinden talebin sürecini takip edebilir.`}</li>
      <li parentName="ul">{`Böl: Aynı talepte birden fazla aksiyonun yapılacağı durumlarda önce talepte bölme işlemi gerçekleştirilir. Talep iki veya daha fazla parçaya ayrılarak her birine ayrı olarak "Teslim Et", "Reddet" veya "Satın Alma Gerekli" aksiyonları uygulanabilir. Bölme işlemi sonundaki toplam miktar orijinal talebin miktarına eşittir, değişmez. Bölme işlemi de diğer aksiyonlar gibi talebin işlem tarihçesine kaydedilir. Örnek olarak gelen talepteki ürün ana depo stoğunda kısmen varsa bu durumda bölme işlemi kullanabiliriz. Elde olan miktar kadarı bölünür ve oluşan birinci talep satırı "Teslim Et" yapılarak süreci sonlandırılır. Oluşan ikinci talep satırı da "Satın Alma Gerekli" aksiyonuyla satın almaya gönderilebilir.`}</li>
    </ul>
    <h3 {...{
      "id": "devam-eden-talepler-1"
    }}>{`Devam Eden Talepler`}</h3>
    <p>{`Depo pozisyonunun işlem yaptığı ama süreci halen devam eden talepler listelenir. Listede her talebin son durumu ve proses detayları görüntülenebilir.`}</p>
    <h3 {...{
      "id": "teslime-hazır-talepler"
    }}>{`Teslime Hazır Talepler`}</h3>
    <p>{`Talebe ilişkin oluşturulan bir satın alma tamamlandıktan sonra, yani mal kabul (satın alma irsaliyesi) işlemi gerçekleşince ilgili talep "Teslime Hazır Talepler" listesine aktarılır. Depo pozisyonu bu listedeki talepleri görüntüler ve proses tasarımında eklenen aksiyonlara bağlı olarak "Teslim Et" veya "Reddet" işlemlerini yapabilir.`}</p>
    <p>{`Talep edilen ürünlere ait ana depo stok durumu talep satırlarında "Envanter" menüsü seçilerek görüntülenebilir.`}</p>
    <p>{`Talebin iş akışıyla ilgili detaylara talep satırındaki "Proses" menüsüne tıklayarak ulaşılır. Proses barında talebin ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <p>{`Aksiyonlar:`}</p>
    <ul>
      <li parentName="ul">{`Teslim Et: Talep edilen ürün (malzeme/hizmet) teslim edilir. Eğer stok takibi yapılan bir ürün ise sistem öncelikle ana depoda ürün için yeterli stok olup olmadığını kontrol eder ve yoksa işlem gerçekleştirilmeyerek kullanıcıya uyarı verir. Eğer yeterli stok varsa işlem gerçekleştirilir. Teslim işlemi stok hareketi yaratır ve ana depoda stok azalır. Ürün teslimi ana depodan alt depoya bir stok transferi değil, doğrudan tüketim gibidir. Teslim işlemi sonunda talebin süreci sonlanır. Talep artık "Teslime Hazır Talepler" listesinden "Tamamlanan Talepler" listesine aktarılır.`}</li>
      <li parentName="ul">{`Reddet: Talep reddedilir. Ret işlemi sonunda talebin süreci sonlanır. Talep artık "Teslime Hazır Talepler" listesinden "Tamamlanan Talepler" listesine aktarılır.`}</li>
    </ul>
    <h3 {...{
      "id": "tamamlanan-talepler-1"
    }}>{`Tamamlanan Talepler`}</h3>
    <p>{`Depo pozisyonu tarafından daha önce işlem yapılmış tüm talepler kalan süreçleri sonlandıktan sonra "Tamamlanan Talepler" listesine aktarılır.`}</p>
    <h3 {...{
      "id": "sipariş-verildi"
    }}>{`Sipariş Verildi`}</h3>
    <p>{`Tedarikçiye satın alma siparişi gönderildikten sonra ilgili satın almalar "Sipariş Verildi" listesine aktarılır. Depo pozisyonu verilen siparişleri listeler ve takip eder. Tedarikçi firmadan siparişleri teslim alacağı zaman listeden kontrolünü sağlayabilir. "Depo Pozisyonu" proses tasarımında eklenen aksiyonlara bağlı olarak "Mal Kabul" veya "İptal Et" işlemlerini yapabilir.`}</p>
    <p>{`Sipariş verilen ürünlere ait ana depo stok durumu sipariş satırlarında "Envanter" menüsü seçilerek görüntülenebilir.`}</p>
    <p>{`Satın alma iş akışıyla ilgili detaylara sipariş satırındaki "Proses" menüsüne tıklayarak ulaşılır. Proses barında satın almanın ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <p>{`Aksiyonlar:`}</p>
    <ul>
      <li parentName="ul">{`Mal Kabul: Seçilen siparişler ile ürünler (malzeme/hizmet) için teslim alma işlemi yapılır. İşlem sırasında seçilen satın alma siparişleri otomatik olarak yeni oluşturulan alım irsaliyesi içine aktarılır. Eğer stok takibi yapılan bir ürün ise mal kabul işlemi stok hareketi yaratır ve ana depoda stok artar. Stok takibi yapılmayan bir ürün ise stok hareketi oluşmaz. Mal kabul işlemi sonunda satın alma süreci sonlanır. Satın alma artık "Sipariş Verildi" listesinden kaldırılır. Satın alma siparişinin ilişkili olduğu talepler artık talep sahibine teslim edilebilmesi için "Teslime Hazır Talepler" listesine aktarılır.`}</li>
      <li parentName="ul">{`İptal Et: Satın alma iptal edilir. İptal işlemi sonunda satın alma süreci sonlanır. Satın alma "Sipariş Verildi" listesinden kaldırılır.`}</li>
    </ul>
    <p>{`Eğer proses tasarımında "Satın Alma" öncesinde "Depo Süreci" varsa iptal edilen satın almanın ilişkili olduğu talep "Devam Eden Talepler" listesinden "Tamamlanan Talepler" listesine aktarılır.`}</p>
    <h3 {...{
      "id": "alım-i̇rsaliyeleri"
    }}>{`Alım İrsaliyeleri`}</h3>
    <p>{`Satın alma irsaliyeleri listelenir. Yeni irsaliye eklemek için (+) seçilir ve yeni alım irsaliye formu açılır. Alım irsaliyesi aynı zamanda "Sipariş Verildi" listesindeyken seçilen sipariş satırlarının "Mal Kabul" aksiyonu tıklanması ile de oluşturulabilir.`}</p>
    <p>{`Alım irsaliyesine sadece beklenen siparişlerden irsaliye satırı eklenebilir. Beklenen siparişler barından gelen siparişler seçilerek irsaliyeye aktarılır.`}</p>
    <p>{`Alım irsaliyesi işlemi kaydedilince irsaliye içine aktarılan (mal kabul yapılan) satın alma siparişleri için artık süreç sonlanır. Satın almalar artık "Sipariş Verildi" listesinden kaldırılır.`}</p>
    <p>{`Satın alma siparişinin ilişkili olduğu talepler proses tasarımında belirlenen depo pozisyonu tarafından talep sahibine teslim edilebilmesi için "Teslime Hazır Talepler" listesine aktarılır.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
TalepNET alım irsaliyelerine sadece verilen satın alma siparişlerinden irsaliye satırı eklenebilir. 
Siparişi verilmeyen bir ürünün alımı yapılamaz.
    </Hint>
    <p>{`Talebe ilişkin satın alma yapıldıktan ve ilgili talep sahibine teslim edildikten sonra satın alma irsaliyesindeki ilgili irsaliye satırı artık silinemez veya değiştirilemez. TalepNET satın alma süreç yönetimi izlenebilirliği sağlamak için kullanıcıya bu konuda uyarı mesajı verir.`}</p>
    <p>{`İlişkili talebi henüz talep sahibine teslim edilmemiş bir irsaliye satırı silinebilir. İrsaliye satırı silinince ilişkili satın alma "Sipariş Verildi" listesine geri aktarılır.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
Alım irsaliyesinden bir irsaliye satırının silinebilmesi için ilişkili talebin talep sahibine depo pozisyonu tarafından "Teslim Et" aksiyonu ile henüz 
teslim edilmemiş olması gereklidir.
    </Hint>
    <p><strong parentName="p">{`Yeni Alım İrsaliyesi:`}</strong></p>
    <ul>
      <li parentName="ul">{`Tarih: İrsaliye tarihini seçin.`}</li>
      <li parentName="ul">{`Döviz: Döviz tipini seçin.`}</li>
      <li parentName="ul">{`İrsaliye No: İrsaliye no yazın.`}</li>
      <li parentName="ul">{`Notlar: Notları yazın.`}</li>
      <li parentName="ul">{`Tedarikçi: Tedarikçi firma seçin.`}</li>
      <li parentName="ul">{`Adres: Adres yazın.`}</li>
      <li parentName="ul">{`Şehir: Şehir seçin.`}</li>
      <li parentName="ul">{`Ülke: Ülke seçin.`}</li>
      <li parentName="ul">{`İrsaliye Satırları: İrsaliye satırlarını sadece beklenen satın alma siparişlerinden seçebilirsiniz.`}
        <ul parentName="li">
          <li parentName="ul">{`Ürün: Ürün değiştirilemez.`}</li>
          <li parentName="ul">{`Miktar: Teslim alınan miktarı yazın.`}</li>
          <li parentName="ul">{`Birim: Birim kodu değiştirilemez.`}</li>
          <li parentName="ul">{`Birim Fiyatı: Birim fiyatını yazın.`}</li>
          <li parentName="ul">{`Net Toplam: Net toplamı yazın.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "talep-onayları"
    }}>{`Talep Onayları`}</h2>
    <p>{`"Talep Onayları" widget'ı sadece "Onaylar" rolüne sahip bir pozisyona atanmış kullanıcının "Konsol" sayfasında görünür. Diğer kullanıcılarda görünmez. Kullanıcı, kendi pozisyonu için onayına gelen talepleri görüntüler ve işlem yapar.`}</p>
    <h3 {...{
      "id": "bekleyen-onaylar"
    }}>{`Bekleyen Onaylar`}</h3>
    <p>{`Proses tasarımında "Talep Onayı" adımı eklenmiş ve kullanıcının pozisyonu seçilmişse oluşturulan talepler proses sırası geldiğinde seçilen pozisyonun onayına gönderilecektir. Kullanıcı "Bekleyen Onaylar" listesinde kendisinin onayını bekleyen talepleri listeler. Proses tasarımında eklenen aksiyonlara bağlı olarak "Onayla" veya "Reddet" aksiyonlarını seçebilir.`}</p>
    <p>{`Talebin iş akışıyla ilgili detaylara talep satırındaki "Proses" menüsüne tıklayarak ulaşılır. Proses barında talebin ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <p>{`Aksiyonlar:`}</p>
    <ul>
      <li parentName="ul">{`Onayla: Talep onaylanır. Onay işlemi sonrasında talep proses tasarımına bağlı olarak yeni bir yönetici onayına, depoya veya satın almaya gider. Talep artık "Bekleyen Onaylar" listesinden "Tamamlanan Onaylar" listesine aktarılır.`}</li>
      <li parentName="ul">{`Reddet: Talep reddedilir. Ret işlemi sonunda talebin süreci sonlanır. Talep artık "Bekleyen Onaylar" listesinden "Tamamlanan Onaylar" listesine aktarılır.`}</li>
    </ul>
    <h3 {...{
      "id": "tamamlanan-onaylar"
    }}>{`Tamamlanan Onaylar`}</h3>
    <p>{`Kullanıcı bir talebe ait onay aksiyonu gerçekleştirdikten sonra ilgili talep artık "Tamamlanan Onaylar" listesine aktarılır. Daha önce onayladığı veya reddettiği tüm talepler listelenir.`}</p>
    <p>{`Talebin iş akışıyla ilgili detaylara talep satırındaki "Proses" menüsüne tıklayarak ulaşılır. Proses barında talebin ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <h2 {...{
      "id": "satın-alma-onayları"
    }}>{`Satın Alma Onayları`}</h2>
    <p>{`"Satın Alma Onayları" widget'ı sadece "Onaylar" rolüne sahip bir pozisyona atanmış kullanıcının "Konsol" sayfasında görünür. Diğer kullanıcılarda görünmez. Kullanıcı, kendi pozisyonu için onayına gelen satın almaları görüntüler ve işlem yapar.`}</p>
    <h3 {...{
      "id": "bekleyen-onaylar-1"
    }}>{`Bekleyen Onaylar`}</h3>
    <p>{`Proses tasarımında "Satın Alma Onayı" adımı eklenmiş ve kullanıcının pozisyonu seçilmişse oluşturulan talepler proses sırası geldiğinde seçilen pozisyonun onayına gönderilecektir. Kullanıcı "Bekleyen Onaylar" listesinde kendisinin onayını bekleyen satın almaları listeler. Proses tasarımında eklenen aksiyonlara bağlı olarak "Onayla" veya "Reddet" aksiyonlarını seçebilir.`}</p>
    <p>{`Satın alma iş akışıyla ilgili detaylara satın alma satırındaki "Proses" menüsüne tıklayarak ulaşılır. Proses barında satın almanın ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <p>{`Aksiyonlar:`}</p>
    <ul>
      <li parentName="ul">{`Onayla: Satın alma onaylanır. Onay işlemi sonrasında satın alma proses tasarımına bağlı olarak yeni bir yönetici onayına veya sipariş adımına gider. Satın alma artık "Bekleyen Onaylar" listesinden "Tamamlanan Onaylar" listesine aktarılır.`}</li>
      <li parentName="ul">{`Reddet: Satın alma reddedilir. Ret işlemi sonunda satın almanın süreci sonlanır. Satın alma artık "Bekleyen Onaylar" listesinden "Tamamlanan Onaylar" listesine aktarılır.`}</li>
    </ul>
    <h3 {...{
      "id": "tamamlanan-onaylar-1"
    }}>{`Tamamlanan Onaylar`}</h3>
    <p>{`Kullanıcı bir satın almaya ait onay aksiyonu gerçekleştirdikten sonra ilgili satın alma artık "Tamamlanan Onaylar" listesine aktarılır. Daha önce onayladığı veya reddettiği tüm satın almalar listelenir.`}</p>
    <p>{`Satın alma iş akışıyla ilgili detaylara satın alma satırındaki "Proses" menüsüne tıklayarak ulaşılır. Proses barında satın almanın ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <h2 {...{
      "id": "satın-alma"
    }}>{`Satın Alma`}</h2>
    <p>{`"Satın Alma" widget'ı yalnız "Satın Alma" rolüne sahip bir pozisyona atanmış kullanıcı tarafından görüntülenebilir. Satın alma sürecinde taleplerin işletilmesinde satın alma ile ilgili operasyonların yürütülmesini sağlar.`}</p>
    <p>{`Satın alma prosesi her sektöre ve firmaya göre değişkenlik gösterebilir. Talepler satın alma formuna dönüştürüldükten sonra teklif ve fiyatları girilerek her ürün için firma/teklif seçimi yapılır. Bu noktadan sonra sipariş öncesinde bir veya birden fazla yöneticinin onayı gerekebilir veya doğrudan sipariş adımına ilerleyebilir. Tüm adımlar proses tasarımında en baştan oluşturulur.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
TalepNET sisteminde her kullanıcının oluşturabildiği ürün(malzeme/hizmet) talepleri firmanın prosesine bağlı olarak depo veya satın almaya yönlendirilse bile kısaca "Talep" olarak adlandırılır.
Satın alma kendisine ulaşan talepleri görüntüler. Seçtiği talepleri "Satın Alma Formu" içine aktarır. Satın alma formundaki her satır artık kısaca "Satın Alma" olarak adlandırılır.
Satın alma formundaki her satın alma satırı (kısaca "Satın Alma") bir "Talep" ile ilişkilidir. Öncesinde bir "Talep" olmadan "Satın Alma" oluşturulamaz.
İlerleyen proses adımlarında "Satın Alma" lar durumlarına göre farklı listelerde görünseler de aslında aynı "Satın Alma" kayıtlarıdır. "Sipariş Verilecek", "Sipariş Verildi", "Satın Alma Onayında" gibi.
    </Hint>
    <h3 {...{
      "id": "bekleyen-talepler"
    }}>{`Bekleyen Talepler`}</h3>
    <p>{`Satın almaya iletilen talepler listelenir. "Satın Alma" rolüne sahip kullanıcı, gelen talepleri listeler ve her biri için uygun işlemleri yapar. Eğer prosesi oluşturulduysa talep "Satın Alma" tarafından "Reddet" yapılabilir. Prensip olarak gelen taleplerden istenilenler işaretlenir ve "Satın Alma Oluştur" seçilerek yeni bir satın alma formu yaratılır.`}</p>
    <p>{`Satın alma formuna aktarılan talepler artık "Bekleyen Talepler" listesinden kaldırılır ve "Devam Eden Talepler" listesinde gösterilir.`}</p>
    <p>{`Talep edilen ürünlere ait ana depo stok durumu talep satırlarında "Envanter" menüsü seçilerek görüntülenebilir.`}</p>
    <p>{`Talebin iş akışıyla ilgili detaylara talep satırındaki "Proses" menüsüne tıklayarak ulaşabilir. Proses barında talebin ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
"Bekleyen Talepler" sayfası ikiye bölünmüştür. Solda işlem bekleyen talepler listelenirken sağda ise oluşturulan satın alma formları listelenir. Talep, satın alma 
formuna aktarılınca soldaki listeden kalkar. Sağdaki satın alma formları henüz teklif eklenip süreci başlatılmamış olan satın alma formlarıdır. Prosesi başlatılarak 
onay veya sipariş aşamasına yönlenen formlar artık sağdaki listeden kaldırılır.
    </Hint>
    <p>{`Aksiyonlar:`}</p>
    <ul>
      <li parentName="ul">{`Satın Alma Oluştur: İşaretlenen taleplerden yeni bir "Satın Alma Formu" yaratır. Bu esnada her talebe ait ürün, miktar ve açıklama bilgileri oluşturulan yeni satın alma formunun satırları içine kopyalanır. Satın alma formunun satırları kendisinin oluşturulduğu "Talep" ile ilişkilidir.
Satın alma formuna aktarılan talepler artık "Bekleyen Talepler" listesinden kaldırılır.`}</li>
      <li parentName="ul">{`Reddet: Talep reddedilir.
Ret işlemi sonunda talebin süreci satın almaya dönüştürülmeden sonlanır. Talep artık "Bekleyen Talepler" listesinden "Tamamlanan Talepler" listesine aktarılır.`}</li>
    </ul>
    <p><strong parentName="p">{`Satın Alma Formu`}</strong></p>
    <p>{`Seçilen "Talepler" den yaratılan "Satın Alma Formu" satırlarında ilgili taleplere ait ürün/miktar/açıklama bilgileri otomatik olarak oluşur.`}</p>
    <p><strong parentName="p">{`Teklif Ekleme:`}</strong></p>
    <ul>
      <li parentName="ul">{`Yeni teklif ekleyebilirsiniz,`}</li>
      <li parentName="ul">{`Önceki tekliflerden ekleyebilirsiniz,`}</li>
      <li parentName="ul">{`Satın alma anlaşmalarından ekleyebilirsiniz.`}</li>
    </ul>
    <p>{`Eklenen teklifler satın alma formunda sütunlar halinde gösterilir. Teklifin satın alma satırındaki ilgili ürüne ait bölümünde birim fiyatı, isk(%), isk-2(%) ve toplam tutar bilgileri yer alır.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Yeni teklif ekleme: Satın alma formunda "Yeni Satın Alma Teklifi" seçilerek açılan yeni teklif formunda teklife ait bilgiler girilebilir.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Tedarikçi: Tedarikçi seçilir.`}</li>
          <li parentName="ul">{`Döviz: Döviz tipi seçilir.`}</li>
          <li parentName="ul">{`Notlar: Notlar yazılır.`}</li>
          <li parentName="ul">{`Tarih: Tarih seçilir.`}</li>
          <li parentName="ul">{`Geçerlilik Tarihi: Geçerlilik tarihi seçilir.`}</li>
          <li parentName="ul">{`İstenen Tarih: İstenen tarih seçilir.`}</li>
          <li parentName="ul">{`Teklif satırları:`}</li>
          <li parentName="ul">{`Ürün: Ürün sabittir, değiştirilemez.`}</li>
          <li parentName="ul">{`Miktar: Miktar sabittir, değiştirilemez.`}</li>
          <li parentName="ul">{`Açıklama: Açıklama yazılır.`}</li>
          <li parentName="ul">{`Birim Fiyatı: Birim fiyatı yazılır.`}</li>
          <li parentName="ul">{`İsk (%): İskonto yüzdesi yazılır.`}</li>
          <li parentName="ul">{`İsk-2 (%): İskonto-2 yüzdesi yazılır.`}</li>
          <li parentName="ul">{`Net Toplam: Net toplam yazılır.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Önceki tekliflerden ekleme:
Satın alma formunda "Önceki Teklifler" tıklandığında sayfanın sağında önceki teklifler paneli görüntülenir. Bu panelde yer alan teklifleri seçerek satın alma formuna önceki teklifin kopyasını oluşturabilir ve isterseniz üzerinde değişiklik yapabilirsiniz.
Önceki teklifler panelinde "Son 12 Ay", "Son 3 Ay" ve "Son 1 Ay" lık dönemler halinde filtreleme yapılabilir.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satın alma anlaşmalarından ekleme:
Satın alma formunda "Anlaşmalı Tedarikçiler" tıklandığında sayfanın sağında anlaşmalı tedarikçiler paneli görüntülenir. Bu panelde satın alma formundaki ürünler için anlaşması bulunan tedarikçi ve anlaşma bilgileri listelenir. İstenilen anlaşma seçilerek satın alma formuna hızlı bir şekilde ürün fiyatlarıyla birlikte yeni teklif olarak oluşturulur.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Teklif Silme:`}</strong></p>
    <p>{`Satın alma formuna eklenen teklif kolonunda "Sil" seçerek teklifi silebilirsiniz.`}</p>
    <p><strong parentName="p">{`Teklif Düzenleme:`}</strong></p>
    <p>{`Satın alma formuna eklenen teklif kolonunda "Düzenle" seçerek teklifi düzenleyebilirsiniz.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
Mevcut bir satın alma formu henüz düzenleme aşamasındayken, yani "Prosesi Başlat" yapmadığınız sürece üzerinde 
değişiklik yapabilirsiniz.
    </Hint>
    <p><strong parentName="p">{`Satır Silme:`}</strong></p>
    <p>{`Satın alma formundaki satırları silmek için formda yer alan "Ürünleri Kaldır" tıklanır. İstediğiniz satırları seçtikten sonra "KALDIR" tıkladığınızda seçili ürün satırları satın alma formundan silinecektir. Silinen satın alma satırlarının ilişkili olduğu talepler yeniden satın almanın "Bekleyen Talepler" listesinde görünür hale gelir. Bu talepler üzerinde istediğiniz işlemi yapabilirsiniz.`}</p>
    <p>{`Satın alma formuna ait tüm satırları silerseniz formun kendisi de silinecektir.`}</p>
    <p><strong parentName="p">{`Satın Alma Formuna Satır Ekleme:`}</strong></p>
    <p>{`Mevcut bir satın alma formu içine prosesi başlatılmadığı sürece "Bekleyen Talepler" listesinden seçilen yeni talepler aktarılabilir. Bunun için "Bekleyen Talepler" sayfasının solunda yer alan listeden istediğiniz talepleri seçer ve sürükle-bırak yöntemiyle sayfanın sağında bulunan satın alma formlarından istediğiniz formun üzerine bırakabilirsiniz. Seçmiş olduğunuz talepler, satın alma formunun içinde satır olarak oluşacaktır.`}</p>
    <p><strong parentName="p">{`En İyi Fiyatları Seç:`}</strong></p>
    <p>{`Ürün bazında en uygun fiyatlı teklifler otomatik olarak seçilir. İsterseniz otomatik seçilen teklifler yerine kendiniz de farklı bir teklif seçebilirsiniz.`}</p>
    <p><strong parentName="p">{`Teklif Talebi`}</strong></p>
    <p>{`Satın alma formunu oluşturduktan sonra tedarikçilere teklif talebi gönderebilirsiniz. Yeni teklif talebi istemek için "Teklif Talebi" tıklayarak açılan sayfa üzerinde gönderim yapacağınız tedarikçi veya tedarikçiler için kontakt kişileri seçerek tek tıkla eposta gönderebilirsiniz.`}</p>
    <ul>
      <li parentName="ul">{`Kime: Teklif talebinin gönderileceği kişileri seçin. Seçim yapabileceğiniz kontakt kişileri "Tedarikçi" tanım formunda daha önce eklemiş olduğunuz "Kişiler" dir.`}</li>
      <li parentName="ul">{`Konu: Teklif talebi epostasının "Konu" alanını değiştirebilirsiniz.`}</li>
      <li parentName="ul">{`Mesaj: Eposta mesajını düzenleyebilirsiniz. Eklediğiniz kişilere teklif talebiniz eposta olarak gönderilir. Eposta mesajına otomatik olarak bir link eklenir. Tedarikçi linke tıklayarak teklif istenilen satın alma formuna ait ürün, miktar ve açıklama bilgilerini "TalepNET Portal" sayfasında tamamen güvenli bir şekilde görüntüler. Teklif formu oluşturur ve kaydeder. Tedarikçiniz teklif oluşturduğunda satın alma formuna doğrudan ekleme yapılmaz. "RFQ MONITOR" panelinde gelen teklifler listelenir ve size bildirim gönderilir. Ancak satın alma formu içine aktarılmasını istediğiniz teklifleri seçtiğinizde tedarikçinin oluşturduğu online teklifler "TalepNET Portal" dan sizin satın alma formunuzun içine alınır.`}</li>
    </ul>
    <p>{`Seçenekler:`}</p>
    <ul>
      <li parentName="ul">{`Varsayılan format yap: Mesaj alanında yaptığınız değişiklikleri saklayarak her yeni teklif talebi oluşturduğunuzda mesaj alanına standart olarak gelmesini sağlar.`}</li>
      <li parentName="ul">{`Standart formatı yükle: Mesaj alanını varsayılan ayarlara geri döndürür.`}</li>
    </ul>
    <Hint type="info" variant="outer" mdxType="Hint">
Teklif Seçimi: Satın alma formunda yer alan teklifler arasından ürün bazında teklif seçimi yapmalısınız. Prosese devam etmek için her ürün için formda yer alan 
teklif kolonlarından bir tanesini seçebilirsiniz. Ürün satırının hizasındaki teklif kolonlarından istediğiniz bir tanesine tıkladığınızda ilgili alan yeşil renge 
dönüşür. Bu, teklifin ilgili satın alma satırında seçildiği anlamındadır. Seçim yaptıktan sonra ilgili seçimi isterseniz değiştirebilirsiniz.
    </Hint>
    <p><strong parentName="p">{`İstatistikler`}</strong></p>
    <p>{`Form satırlarında ürün adının altında "İstatistikler" tıklayarak ilgili ürün için satın alma analizlerinin ve envanter durumunun gösterildiği istatistik panelini görüntülersiniz. Satın alınacak ürüne ait daha önce almış olduğunuz fiyat tekliflerinin ortalama, en yüksek ve en düşük değerleri ay bazında grafik olarak gösterilir.`}</p>
    <p><strong parentName="p">{`Prosesin Başlatılması`}</strong></p>
    <p>{`Satın alma formuna en az 1 teklif ekleyip her ürün için uygun teklif seçimi yaptıktan sonra satın alma sürecini başlatabilirsiniz. Bunun için "Prosesi Başlat" tıklayarak proses tasarımında daha önce belirlediğiniz adımlara göre süreci işletirsiniz. Firma satın alma süreçlerinize bağlı olarak satın alma satırlarının her biri "Satın Alma Onayı" veya "Sipariş Verilecek" adımlarından birine ilerler.`}</p>
    <p>{`Satın alma başlatıldıktan sonraki ilk proses adımı "Satın Alma Onayı" ise satın almalar ilgili yönetici pozisyonun onayına gönderilir. Eğer "Sipariş Verilecek" ise konsol sayfasında satın almanın "Sipariş Verilecek" listesine aktarılır.`}</p>
    <p>{`Satın alma formlarını prosesleri başlatılana kadar "Bekleyen Talepler" listesinde sayfanın sağındaki panelde görüntüleyebilir ve üzerinde değişiklik yapabilirsiniz. Prosesi başlatılan satın alma formları ise artık bu listeden kaldırılır.`}</p>
    <h3 {...{
      "id": "tamamlanan-talepler-2"
    }}>{`Tamamlanan Talepler`}</h3>
    <p>{`Satın alma tarafından daha önce işlem yapılmış bir talep kalan süreci sonlandıktan sonra "Tamamlanan Talepler" listesinde görüntülenir.`}</p>
    <h3 {...{
      "id": "onay-bekleniyor"
    }}>{`Onay Bekleniyor`}</h3>
    <p>{`Satın alma tarafından prosesi başlatılan ve şu anda bir yöneticinin satın alma onayında işlem bekleyen satın almalar listelenir.`}</p>
    <h3 {...{
      "id": "sipariş-verilecek"
    }}>{`Sipariş Verilecek`}</h3>
    <p>{`Tedarikçiye sipariş verilmesi beklenen satın almalar listelenir. Eğer prosesi oluşturulduysa satın alma tarafından "Sipariş Ver", "Sipariş Verildi Yap" veya "Satın Alma İptal" yapılabilir.`}</p>
    <p>{`Sipariş verilecek ürünlere ait ana depo stok durumu satın alma satırlarında "Envanter" menüsü seçilerek görüntülenebilir.`}</p>
    <p>{`Satın alma iş akışıyla ilgili detaylara satın alma satırındaki "Proses" menüsüne tıklayarak ulaşılır. Proses barında satın almanın ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <p>{`Aksiyonlar:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Sipariş Ver: Satın alma satırlarını seçtikten sonra "Sipariş Ver" tıklayarak tedarikçinize sipariş epostası oluşturursunuz. Açılan sayfa üzerinde gönderim yapacağınız tedarikçinin kayıtlı kontakt kişilerinden seçim yaparak tek tıkla eposta gönderirsiniz.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Kime: Siparişin gönderileceği kişileri seçin. Seçim yapabileceğiniz kontakt kişileri "Tedarikçi" tanım formunda daha önce eklemiş olduğunuz "Kişiler" dir.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Konu: Sipariş epostasının "Konu" alanını değiştirebilirsiniz.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Mesaj: Eposta mesajını düzenleyebilirsiniz. Eklediğiniz kişilere siparişiniz eposta olarak gönderilir. Eposta mesajına otomatik olarak bir link eklenir. Tedarikçi linke tıklayarak sipariş bilgilerini "TalepNET Portal" sayfasında tamamen güvenli bir şekilde görüntüler. Tedarikçiniz siparişe onay/ret verebilir. Size de bildirim gönderilir.`}</p>
            <p parentName="li">{`Sipariş gönderildikten sonra ilgili satın almalar "Sipariş Verilecek" listesinden "Sipariş Verildi" listesine aktarılır.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Seçenekler:`}</p>
            <ul parentName="li">
              <li parentName="ul">{`Varsayılan format yap: Mesaj alanında yaptığınız değişiklikleri saklayarak her yeni sipariş oluşturduğunuzda mesaj alanına standart olarak gelmesini sağlar.`}</li>
              <li parentName="ul">{`Standart formatı yükle: Mesaj alanını varsayılan ayarlara geri döndürür.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sipariş Verildi Yap: Satın alma siparişini sistem üzerinde eposta ile göndermeden prosesi "Sipariş Verildi" adımına getirmeyi sağlar. İlgili satın almalar "Sipariş Verilecek" listesinden "Sipariş Verildi" listesine aktarılır.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satın Alma İptal: Satın almanın siparişini vermeden iptal edebilirsiniz.`}</p>
      </li>
    </ul>
    <p>{`İptal işlemi sonunda satın almanın süreci sonlanır. Satın almalar artık "Sipariş Verilecek" listesinden "Tamamlanan Satın Almalar" listesine aktarılır.
İptal işlemi aynı zamanda satın almanın ilişkili olduğu talebin de sürecini sonlandırır. Talep, "Tamamlanan Talepler" listesine aktarılır.`}</p>
    <h3 {...{
      "id": "sipariş-verildi-1"
    }}>{`Sipariş Verildi`}</h3>
    <p>{`Tedarikçiye satın alma siparişi gönderildikten sonra ilgili satın almalar "Sipariş Verildi" listesinde gösterilir.`}</p>
    <p>{`Sipariş verilen ürünlere ait ana depo stok durumu sipariş satırlarında "Envanter" menüsü seçilerek görüntülenebilir.`}</p>
    <p>{`Satın alma iş akışıyla ilgili detaylara sipariş satırındaki "Proses" menüsüne tıklayarak ulaşılır. Proses barında satın almanın ilk yaratılmasından şu an gelinen proses adımına kadar yapılan işlem adımları, zamanı ve kim tarafından işlem yapıldığı bilgisi sırasıyla listelenir.`}</p>
    <h3 {...{
      "id": "tamamlanan-satın-almalar"
    }}>{`Tamamlanan Satın Almalar`}</h3>
    <p>{`Satın Alma pozisyonu tarafından daha önce işlem yapılmış tüm satın almalar kalan süreçleri sonlandıktan sonra "Tamamlanan Satın Almalar" listesinde görüntülenir.`}</p>
    <h3 {...{
      "id": "muhasebe"
    }}>{`Muhasebe`}</h3>
    <p>{`"Muhasebe" widget'ı yalnız "Muhasebe" rolüne sahip bir pozisyona atanmış kullanıcının "Konsol" sayfasında görünür. Diğer kullanıcılarda görünmez. Satın alma yönetiminde fatura sürecinin yürütülmesini sağlar.`}</p>
    <h3 {...{
      "id": "satın-alma-faturaları"
    }}>{`Satın Alma Faturaları`}</h3>
    <p>{`Satın alma faturaları listelenir. Yeni fatura eklemek için (+) seçilir ve yeni satın alma fatura formu açılır.`}</p>
    <p>{`Satın alma faturasına sadece satın alma irsaliyelerinden fatura satırı eklenebilir. "Alım İrsaliyeleri" tıklandığında seçilen tedarikçiye ait faturalanmamış irsaliye satırları listelenir. İstenilen irsaliye satırları seçilerek faturaya aktarılır.`}</p>
    <p>{`Alım faturası kaydedilince fatura içine aktarılan irsaliye satırları "Faturalandı" durumuna getirilir.`}</p>
    <Hint type="info" variant="outer" mdxType="Hint">
TalepNET'te "Alım Faturası" proses tasarımında bir adım olarak yer almasa da modül olarak uygulamada vardır. Satın alma tamamlandıktan, yani alım irsaliyesi 
oluşturulduktan sonra alım faturasını da TalepNET üzerinde oluşturabilirsiniz.
    </Hint>
    <p>{`Faturalandırılan bir irsaliye satırı artık silinemez veya değiştirilemez.`}</p>
    <p><strong parentName="p">{`Yeni Satın Alma Faturası:`}</strong></p>
    <ul>
      <li parentName="ul">{`Tarih: Fatura tarihini seçin.`}</li>
      <li parentName="ul">{`Döviz: Döviz tipini seçin.`}</li>
      <li parentName="ul">{`Fatura No: Fatura no yazın.`}</li>
      <li parentName="ul">{`Notlar: Notları yazın.`}</li>
      <li parentName="ul">{`Tedarikçi: Tedarikçi firma seçin.`}</li>
      <li parentName="ul">{`Adres: Adres yazın.`}</li>
      <li parentName="ul">{`Şehir: Şehir seçin.`}</li>
      <li parentName="ul">{`Ülke: Ülke seçin.`}</li>
      <li parentName="ul">{`Fatura Satırları: Fatura satırlarını sadece faturalanmamış irsaliye satırlarından seçebilirsiniz.`}
        <ul parentName="li">
          <li parentName="ul">{`Ürün: Ürün değiştirilemez.`}</li>
          <li parentName="ul">{`Miktar: Miktarı yazın.`}</li>
          <li parentName="ul">{`Birim: Birim kodu değiştirilemez.`}</li>
          <li parentName="ul">{`Birim Fiyatı: Birim fiyatını yazın.`}</li>
          <li parentName="ul">{`Net Toplam: Net toplamı yazın.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      